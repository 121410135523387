import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { saveAs } from 'file-saver';
import { commonServices } from '../services/index';
import ActionModal from "./Modals/ActionModal";
import ToastComponent from './ToastComponent';
import { TOAST_TIMEOUT } from "../constants";

const ToastData = {
    message: '',
    type: '',
    isOpen: false
}

const ActionButton = ({ action, onClick, className, color, label, disabled, version, children }) => {
    const history = useHistory();

    const [showModal, setShowModal] = useState(false);

    const [toastInfo, setToastInfo] = useState(ToastData);

    const onClickHandler = () => {
        if (action === 'Export') {
            commonServices.exportData(version)
                .then((response) => {
                    // Handle successful response
                    saveAs(response.data, `Version-${version}.csv`);
                    setToastInfo({ message: 'File Exported successfully', type: 'info', isOpen: true });
                    closeToastMessage();
                })
                .catch((error) => {
                    // Handle errors
                    console.error('An error occurred:', error);
                    setToastInfo({ message: 'An error occurred while exporting data', type: 'danger', isOpen: true });
                    closeToastMessage();
                });

        } else {
            setShowModal(true);
        }
    }

    const closeToastMessage = () => {
        setTimeout(() => {
            setToastInfo(ToastData)
        }, TOAST_TIMEOUT);
    }

    const onModalCancel = ({action, message, type, redirectTo}) => {
        if(action === 'success'){
            setToastInfo({ message: message, type: 'info', isOpen: true });
            closeToastMessage();
            setShowModal(false);
            setTimeout(() => {
                if(redirectTo){
                    history.push(`/v/${redirectTo}`);
                } else {
                    
                }

                window.location.reload(true);
                
            }, TOAST_TIMEOUT)
        } else if(action === 'error'){
            setToastInfo({ message: message, type: 'danger', isOpen: true });
            closeToastMessage();
            setShowModal(false);
        } else {
            setShowModal(false);
        }
        console.log("action", action, message);
       
    }

    const onModalSubmit = () => {
        setShowModal(false);
    }

    return (
        <>
            <Button
                disabled={disabled}
                className={className}
                color={color}
                onClick={onClickHandler}>
                {children && <span style={{paddingRight: '10px'}}>{children}</span>}
                {label}
            </Button>
            {showModal &&
                <ActionModal
                    action={action}
                    show={showModal}
                    headerTitle='Import CSV'
                    onCancel={onModalCancel}
                    onSubmit={onModalSubmit}
                    version={version}
                />}
            {toastInfo.isOpen && <ToastComponent {...toastInfo}/>}    
        </>
    )
}

export default ActionButton;