import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { commonServices } from "../../services";
import closeIcon from "../../images/close-icon.png";
import errorIcon from "../../images/Error.png";

const DeleteDetectionModal = ({ ids, show, onCancel, version }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSubmit = () => {
    setIsDeleting(true);
    commonServices
      .deleteDetections({ versionNumber: version, ids })
      .then((res) => {
        onCancel({
          action: "success",
          type: "delete",
          redirectTo: null,
          message: res?.data?.message,
        });
        setIsDeleting(false);
      })
      .catch((error) => {
        setIsDeleting(false);
      });
  };

  return (
    <Modal isOpen={show} size="lg" centered className="custom-modal">
      <div className="modal-header">
        <h5 className="modal-title text-danger">Delete Confirmation !</h5>
        {!isDeleting && (
          <span
            className="close-icon"
            onClick={() => onCancel({ action: "cancel" })}
          >
            <img src={closeIcon} alt="close icon" />
          </span>
        )}
      </div>
      <ModalBody>
        <div className="modal-body-wrapper">
          <div className="modal-content">
            <div className="upload-icon">
              <img src={errorIcon} alt="upload icon" />
            </div>
            <div className="label-text">
              Are you sure to delete the selected detections?
            </div>
            <div className="label-text">
              This action will permanently remove the data associated with the
              selected detections.
            </div>
            <div className="label-text">Kindly confirm!</div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="modal-custom-footer">
        <Button
          className="btn-custom"
          color="primary"
          disabled={isDeleting}
          onClick={() => onCancel({ action: "cancel" })}
        >
          Cancel
        </Button>{" "}
        {!isDeleting && (
          <Button className="btn-custom" color="warning" onClick={handleSubmit}>
            Ok
          </Button>
        )}
        {isDeleting && (
          <Button color="warning" disabled>
            <Spinner size="sm">Loading...</Spinner>
            <span> Deleting</span>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default DeleteDetectionModal;
