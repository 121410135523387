import React, { Component } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  // InputGroup,
  // InputGroupAddon,
} from "reactstrap";
import _, { isEmpty } from "lodash";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  // GridToolbarDensitySelector,
} from "@material-ui/data-grid";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";

// import DetectionTable from "./DetectionTable";
// import NoRecords from "./NoRecords";
import LegendsComponent from "./LegendsComponent";
import LoaderComponent from "./LoaderComponent";
// import Autocomplete from "./Autocomplete";
import Select, { createFilter } from "react-select";
import { commonServices } from "../services";
// import { withStyles } from "@material-ui/core";

// const StyledDataGrid = withStyles({
//     root: {
//         "& .MuiDataGrid-renderingZone": {
//         maxHeight: "none !important"
//         },
//         "& .MuiDataGrid-cell": {
//         lineHeight: "unset !important",
//         maxHeight: "none !important",
//         whiteSpace: "normal"
//         },
//         "& .MuiDataGrid-row": {
//         maxHeight: "none !important"
//         }
//     }
// })(DataGrid);

import DeleteDetectionModal from "../components/Modals/DeleteDetectionModal";

import AddNewDataSourceModal from "./Modals/AddNewDataSourceModal";
import AddEditDetectionModal from "./Modals/AddEditDetectionModal";
import ToastComponent from "./ToastComponent";
import { TOAST_TIMEOUT, newDetectionPayload } from "../constants/";

import ActionButton from "../components/ActionButton";
import importIcon from "../images/Import.png";
import exportIcon from "../images/Export.png";
import createNewIcon from "../images/create_new.png";
import cloneIcon from "../images/clone.png";
import deleteIcon from "../images/delete.png";
import AddDetectionModal from "./Modals/AddDetectionModal";

const ToastDefaultData = {
  message: "",
  type: "",
  isOpen: false,
};

class TableWithFiltersLatest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      source_type: [],
      _data_sources: [],
      sourceTypeArray: [],
      totalLength: 0,
      filters: [],
      filteredData: [],
      searchValue: "",
      isLoading: false,
      isSourceLoading: false,
      detectionSearch: [],
      searchByDetectionArray: [],
      allDetections: [],
      suggestionsArray: [],
      isFieldsSelected: true,
      isMultiSelect: false,
      pageSize: 10,
      addNewSourceInfo: {
        show: false,
        dataSource: "",
        existingDataSources: [],
      },
      ToastInfo: ToastDefaultData,
      showDeleteDetectionModal: false,
      showEditDetectionModal: false,
      showAddDetectionModal: false,
      selectedRowIds: [],
      isSelectAll: false,
      selectedRow: {},
      XDR_Kill_Chain_statge_List: [],
      XDR_Techniques: [],
    };
    this.multiSelectRef = [];
    this.searchRef = "";
    this.mixedSearchRef = "";
    this.version = this.props.version;
    this.selectedFilter = "";
    this.isSourcesDisabled = false;
    this.isDetectionSearchDisabled = false;
    this.isMixedSearchDisabled = false;
    this.isAdmin = this.props.isAdmin;
    this.resetAddNewSourceInfo = this.resetAddNewSourceInfo.bind(this);
    this.handleRowEdit = this.handleRowEdit.bind(this);
    this.handleRowDelete = this.handleRowDelete.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.handleSelectAllRows = this.handleSelectAllRows.bind(this);
    this.handleDeleteRows = this.handleDeleteRows.bind(this);

    this.actionsRef = React.createRef();
  }

  clearSelectValues = () => {
    _.each(this.multiSelectRef, (selectRef) => {
      selectRef.select.clearValue();
    });
    this.searchRef.select.clearValue();
    this.mixedSearchRef.select.clearValue();
  };

  setRef = (ref) => {
    this.multiSelectRef.push(ref);
    // console.log('this.multiSelectRef: ', this.multiSelectRef);
  };

  filterResults = () => {
    this.setState({ isLoading: true });
    this.selectedFilter = "";

    const { sourceTypeArray, detectionSearch, allDetections, searchValue } = this.state;

    const filterBySources = () => {
      const itemIds = _.map(sourceTypeArray, "_id");
      const selectedItemsArray = _.flatten([itemIds]);
      const normalizeSources = (source) => Array.isArray(source) ? source : source.split(',').map(s => s.trim());

      const filteredData = allDetections.filter(item => {
        const fieldsToCheck = [
          'data_sources_default',
          'data_sources_dependency',
          'data_sources_optional',
          'data_sources_recommended',
          'data_sources_required'
        ];
        return fieldsToCheck.some(field => {
          const sources = normalizeSources(item[field]);
          return sources.some(source => selectedItemsArray.includes(source));
        });
      });

      return { filteredData, selectedSources: { selected_sources: selectedItemsArray } };
    };

    const filterByDetectionSearch = () => {
      return _.flatten(detectionSearch.map(d =>
        _.filter(allDetections, item => item["XDR Display Name"] === d.value)
      ));
    };

    const filterBySearchValue = () => {
      return _.flatten(searchValue.map(d =>
        _.filter(allDetections, item =>
          item["XDR Kill Chain Stage"] === d.value ||
          item["XDR Tactic"] === d.value ||
          item["XDR Technique"] === d.value
        )
      ));
    };

    let filteredData = [];
    if (!_.isEmpty(sourceTypeArray)) {
      const { filteredData: sourcesFilteredData } = filterBySources();
      filteredData = sourcesFilteredData;
    } else if (!_.isEmpty(detectionSearch)) {
      filteredData = filterByDetectionSearch();
    } else if (!_.isEmpty(searchValue)) {
      filteredData = filterBySearchValue();
    }

    this.setState({
      filteredData,
      totalLength: filteredData.length,
      isFieldsSelected: true,
      isLoading: false,
      detectionSearch: !_.isEmpty(detectionSearch) ? detectionSearch : undefined,
      searchValue: !_.isEmpty(searchValue) ? searchValue : undefined,
    });

    this.isSourcesDisabled = false;
    this.isDetectionSearchDisabled = false;
    this.isMixedSearchDisabled = false;
  };

  clearResults = () => {
    this.selectedFilter = "clear";

    this.isSourcesDisabled = false;
    this.isDetectionSearchDisabled = false;
    this.isMixedSearchDisabled = false;

    this.clearSelectValues();

    const allDetections = [...this.state.allDetections];

    this.setState({
      sourceTypeArray: [],
      totalLength: allDetections.length,
      filteredData: allDetections,
      searchValue: "",
      isFieldsSelected: true,
      detectionSearch: [],
    });

    this.selectedFilter = "";
  };


  onSourceChange = () => {
    // let filterData = [...this.state.filteredData];
    if (this.selectedFilter == "" || this.selectedFilter === "Data Sources") {
      this.selectedFilter = "Data Sources";
      this.isSourcesDisabled = false;
      this.isDetectionSearchDisabled = true;
      this.isMixedSearchDisabled = true;

      this.searchRef.select.clearValue();
      this.mixedSearchRef.select.clearValue();
      let isMultiBoxSelected = false;
      const multiSelectBoxes = this.multiSelectRef;
      const scope = this;
      setTimeout(function () {
        let sourceUpdatedArray = [];
        _.each(multiSelectBoxes, function (s) {
          if (!_.isEmpty(s.state.value)) {
            isMultiBoxSelected = true;
            sourceUpdatedArray.push(s.state.value);
          }
        });
        sourceUpdatedArray = _.flatten(sourceUpdatedArray);
        sourceUpdatedArray = _.uniq(sourceUpdatedArray);

        if (_.isEmpty(sourceUpdatedArray) || (!isMultiBoxSelected && !scope.state.isFieldsSelected)) {
          scope.clearResults()
        } else {
          scope.setState({
            sourceTypeArray: sourceUpdatedArray,
            isFieldsSelected: false,
            detectionSearch: [],
          });
        }
      }, 400);
    } else {
      return false;
    }
  };

  onMixedSearchChange = (selectedItems) => {
    if (this.selectedFilter === "" || this.selectedFilter === "Mixed Search") {
      this.selectedFilter = "Mixed Search";
      this.isSourcesDisabled = true;
      this.isDetectionSearchDisabled = true;
      this.isMixedSearchDisabled = false;
      let allDetections = [...this.state.allDetections];
      if (!_.isEmpty(selectedItems)) {
        _.each(this.multiSelectRef, function (sel) {
          sel.select.clearValue();
        });
        this.searchRef.select.clearValue();
        let scope = this;
        setTimeout(function () {
          scope.setState({
            isFieldsSelected: false,
            detectionSearch: [],
            sourceTypeArray: [],
            searchValue: selectedItems,
          });
        }, 400);
      } else {
        let scope = this;
       
        setTimeout(function () {
          scope.clearResults()
        }, 400);
      }
    } else {
      return false;
    }
  };

  onDetectionSearchChange = (selectedItems) => {
    if (
      this.selectedFilter === "" ||
      this.selectedFilter === "Detection Search"
    ) {
      this.selectedFilter = "Detection Search";
      this.isSourcesDisabled = true;
      this.isDetectionSearchDisabled = false;
      this.isMixedSearchDisabled = true;
      let allDetections = [...this.state.allDetections];
      if (!_.isEmpty(selectedItems)) {
        _.each(this.multiSelectRef, function (sel) {
          sel.select.clearValue();
        });
        this.mixedSearchRef.select.clearValue();
        let scope = this;
        setTimeout(function () {
          scope.setState({
            isFieldsSelected: false,
            detectionSearch: selectedItems,
            sourceTypeArray: [],
          });
        }, 400);
      } else {
        let scope = this;
       
        setTimeout(function () {
          scope.clearResults()
        }, 400);
      }
    } else {
      return false;
    }
  };

  addNewDataSource(name, data) {
    this.setState({
      addNewSourceInfo: {
        show: true,
        dataSource: name,
        existingDataSources: data,
      },
    });
  }

  resetAddNewSourceInfo(action) {
    if (action === "success") {
      this.setState({
        // addNewSourceInfo: {
        //   //show: false,
        //   dataSource: "",
        //   existingDataSources: [],
        // },
        ToastInfo: {
          message: "Data Source added successfully !!",
          type: "info",
          isOpen: true,
        },
      });
      this.getDataSource('add');
      this.closeToastMsg();
    } else if (action === "cancel") {
      this.setState({
        addNewSourceInfo: {
          show: false,
          dataSource: "",
          existingDataSources: [],
        },
      });
    }
  }

  closeToastMsg() {
    setTimeout(() => {
      this.setState({ ToastInfo: ToastDefaultData });
    }, TOAST_TIMEOUT);
  }

  renderSourcesSelect() {
    let source_types = [...this.state.source_type];
    let dataSources = [...this.state._data_sources];
    let selectBoxesValues = [];
    source_types = _.orderBy(source_types, ["order"], ["asc"]);
    _.each(source_types, function (source_type) {
      let data = _.filter(dataSources, function (o) {
        return (
          o._source_type_id === source_type._id &&
          ((o.label = o.name), (o.value = o._id))
        );
      });
      // console.log("source_type: ", source_type)
      data = _.orderBy(data, ["name"], ["asc"]);
      selectBoxesValues.push(data);
    });
    return selectBoxesValues.map((sourcTypeArray, index) => (
      <Col xs="3" key={index}>
        <label className="w-100">
          Select {selectBoxesValues[index][0]._source_type}:
          {this.isAdmin &&
            selectBoxesValues[index][0]._source_type !== "Other" && (
              <span
                className="addNewDataSource"
                onClick={() =>
                  this.addNewDataSource(
                    selectBoxesValues[index][0]._source_type,
                    sourcTypeArray
                  )
                }
              >
                + Add New
              </span>
            )}
        </label>
        <Select
          isMulti
          name={selectBoxesValues[index][0]._source_type}
          options={sourcTypeArray}
          className="basic-multi-select"
          classNamePrefix="select"
          isDisabled={this.isSourcesDisabled}
          closeMenuOnSelect={false}
          ref={this.setRef}
          onChange={(e) =>
            this.onSourceChange(e, selectBoxesValues[index][0]._source_type)
          }
        />
      </Col>
    ));
  }

  searchPanel = () => {
    return (
      <div>
        <Card className="panel">
          <Row>
            <Col xs="12">
              <div className="panel-heading">Search by Data Source:</div>
            </Col>
          </Row>
          <Row>{this.renderSourcesSelect()}</Row>
          <Row>
            <Col xs="12">
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs="5" className="d-flex align-items-center">
              <div className="panel-label">OR Search By Alert Types Name: </div>
            </Col>
            <Col xs="7">
              {this.isAdmin && (
                <span
                  className="addNewDataSource"
                  style={{
                    float: "none",
                    display: "block",
                    textAlign: "right",
                  }}
                  onClick={() => this.addNewDataSource("New Alert Type")}
                >
                  + Add New
                </span>
              )}
              <Select
                name="searchByDetection"
                isMulti
                options={this.state.searchByDetectionArray}
                className="basic-multi-select"
                classNamePrefix="select"
                isDisabled={this.isDetectionSearchDisabled}
                isClearable
                closeMenuOnSelect={false}
                placeholder="Search By Detection Name"
                ref={(ref) => {
                  this.searchRef = ref;
                }}
                filterOption={createFilter({
                  ignoreCase: true,
                  ignoreAccents: true,
                  trim: true,
                  matchFromStart: false,
                })}
                onChange={this.onDetectionSearchChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs="5" className="d-flex align-items-center">
              <div className="panel-label">
                OR Search by <br />
                (XDR Kill Chain Stage / XDR tactic / XDR Technique):
              </div>
            </Col>
            <Col xs="7">
              <Select
                name="searchBySuggestionsArray"
                isMulti
                options={this.state.suggestionsArray}
                className="basic-multi-select"
                classNamePrefix="select"
                isDisabled={this.isMixedSearchDisabled}
                isClearable
                closeMenuOnSelect={false}
                placeholder="Search By XDR Kill Chain Stage / XDR tactic / XDR Technique"
                ref={(ref) => {
                  this.mixedSearchRef = ref;
                }}
                filterOption={createFilter({
                  ignoreCase: true,
                  ignoreAccents: true,
                  trim: true,
                  matchFromStart: false,
                })}
                onChange={this.onMixedSearchChange}
              />
              {/* <InputGroup>
								<InputGroupAddon
									color="primary"
									addonType="prepend">
									<i className="fa fa-search"></i> Search
								</InputGroupAddon>
								<Autocomplete
									showSuggestions={true}
									searchChange={this.onMixedSearchChange}
									clearSearch={this.state.searchValue}
									suggestions={this.state.suggestionsArray}
								/>
							</InputGroup> */}
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs="6" className="d-flex align-items-center result-text">
              Showing total results of {this.state.totalLength} Alert Types.
            </Col>
            <Col xs="6">
              <Button
                disabled={this.state.isFieldsSelected}
                className="float-right"
                color="warning"
                onClick={this.filterResults}
              >
                Show Results
              </Button>
              <Button
                className="float-right mr-2"
                color="info"
                onClick={this.clearResults}
              >
                Clear Results
              </Button>
            </Col>
          </Row>
        </Card>
        <LegendsComponent />
      </div>
    );
  };

  getDataSource(action) {
    //this.props.setVersion(this.version);
    commonServices.getSourceTypes().then((response) => {
      let sourceTypesObject = response.data.source_types;
      // console.log("sourceTypesObject: ", sourceTypesObject);
      this.setState({
        source_type: sourceTypesObject,
      });
      commonServices.getDataSources().then((response) => {
        let dataSourcesObject = response.data.data_sources;
        if (action === 'add') {
          const _existingDataSources = _.filter(dataSourcesObject, { _source_type: this.state.addNewSourceInfo.dataSource });
          this.setState({
            addNewSourceInfo: {
              ...this.state.addNewSourceInfo,
              existingDataSources: _.reverse(_existingDataSources),
            },
            _data_sources: dataSourcesObject,
            isSourceLoading: true,
          });
        } else {
          this.setState({
            _data_sources: dataSourcesObject,
            isSourceLoading: true,
          });
        }
        
      });
    });

    commonServices.getAllDetections(this.version).then((response) => {
      response.data.detections = _.reverse(response.data.detections);
      
      let searchByDetectionArray = [];
      let suggestionsArray = [];
      let XDR_Kill_Chain_statge_List = [];
      let XDR_Techniques = [];
    
      if (response.data.status === "OK") {
        let allDetections = response.data.detections;
    
        _.each(allDetections, (d) => {
          searchByDetectionArray.push({
            label: d["XDR Display Name"],
            value: d["XDR Display Name"],
          });
    
          suggestionsArray.push({
            label: d["XDR Kill Chain Stage"],
            value: d["XDR Kill Chain Stage"],
          });
          suggestionsArray.push({
            label: d["XDR Tactic"],
            value: d["XDR Tactic"],
          });
          suggestionsArray.push({
            label: d["XDR Technique"],
            value: d["XDR Technique"],
          });
    
          XDR_Kill_Chain_statge_List.push({
            label: d["XDR Kill Chain Stage"],
            value: d["XDR Kill Chain Stage"],
          });
    
          XDR_Techniques.push({
            label: d["XDR Technique"],
            value: d["XDR Technique"],
          });
        });
    
        // Remove duplicates using _.uniqBy
        searchByDetectionArray = _.uniqBy(searchByDetectionArray, "label");
        suggestionsArray = _.uniqBy(suggestionsArray, "label");
        XDR_Kill_Chain_statge_List = _.uniqBy(XDR_Kill_Chain_statge_List, "label");
        XDR_Techniques = _.uniqBy(XDR_Techniques, "label");
    
        // Sort the searchByDetectionArray
        searchByDetectionArray = _.orderBy(searchByDetectionArray, ["label"], ["asc"]);
    
        this.setState({
          searchByDetectionArray,
          filteredData: allDetections,
          totalLength: allDetections.length,
          allDetections,
          suggestionsArray,
          isSelectAll: false,
          XDR_Kill_Chain_statge_List,
          XDR_Techniques,
        });
      }
    });
  }

  componentDidMount() {
    this.getDataSource();
  }

  componentWillUnmount() {}

  setPageSize(pageNumber) {
    this.setState({ pageSize: pageNumber });
  }

  renderTags(params) {
    let tagData = [];
    let row = params.row;

    const processArray = (dataArray, className) => {
      if (dataArray instanceof Array) {
        const filteredArray = dataArray.filter((item) => item.trim() !== "");
        tagData.push(
          ...filteredArray.map((d) => ({ name: d, class: className }))
        );
      } else if (typeof dataArray === "string") {
        const splitArray = dataArray
          .split(",")
          .map((s) => s.trim())
          .filter((s) => s !== "");
        tagData.push(...splitArray.map((s) => ({ name: s, class: className })));
      }
    };

    processArray(row["data_sources_default"], "default");
    processArray(row["data_sources_optional"], "optional");
    processArray(row["data_sources_recommended"], "recommended");
    processArray(row["data_sources_required"], "required");
    processArray(row["data_sources_dependency"], "dependency");

    return (
      tagData.length > 0 &&
      tagData.map((d, index) => {
        const cellValue = d.name.replace(/_/g, " ");
        return (
          <span className={"significance " + d.class} key={index}>
            {cellValue}
          </span>
        );
      })
    );
  }

  handleRowEdit(row) {
    const newState = {
      showEditDetectionModal: true,
      selectedRow: row,
    };
    this.setState(newState);
  }

  handleRowDelete(id) {
    const { selectedRowIds } = this.state;
    const nextState = {
      showDeleteDetectionModal: true,
      selectedRowIds: id ? [id] : selectedRowIds,
    };
    this.setState(nextState);
  }

  handleSelectAllRows(evt) {
    const isChecked = evt.target.checked;
    const selectedRowIds = isChecked
      ? this.state.filteredData.map((row) => row._id)
      : [];
    const updatedData = this.state.filteredData.map((row) => ({
      ...row,
      isChecked,
    }));
    this.setState({
      filteredData: updatedData,
      isSelectAll: isChecked,
      selectedRowIds,
    });
  }

  handleDeleteRows(evt, id) {
    const isChecked = evt.target.checked;
    const { selectedRowIds, filteredData } = this.state;

    const updatedData = filteredData.map((row) => {
      if (row._id === id) {
        if (isChecked) {
          selectedRowIds.push(row._id);
        } else {
          const index = selectedRowIds.indexOf(row._id);
          if (index !== -1) {
            selectedRowIds.splice(index, 1);
          }
        }
        return { ...row, isChecked };
      }
      return row;
    });

    const countSelectedRows = updatedData.filter((row) => row.isChecked);
    const isSelectAll = filteredData.length === countSelectedRows.length;
    this.setState({ filteredData: updatedData, isSelectAll, selectedRowIds });
  }

  onModalClose({ action, type, redirectTo, message }) {
    if (action === "success") {
      this.setState({
        showDeleteDetectionModal: false,
        showEditDetectionModal: false,
        showAddDetectionModal: false,
        selectedRowIds: null,
        selectedRow: {},
        isSelectAll: false,
        ToastInfo: {
          message: message,
          type: "info",
          isOpen: true,
        },
      });
      this.getDataSource();
      this.closeToastMsg();
    } else if (action === "cancel") {
      this.setState({
        showAddDetectionModal: false,
        showDeleteDetectionModal: false,
        showEditDetectionModal: false,
        selectedRowIds: null,
        selectedRow: {},
      });
    }
  }

  showAddNewDetection() {
    this.setState({
      showAddDetectionModal: true,
    });
  }

  getColumns() {
    let ProcessesColumns = [
      {
        field: "XDR Display Name",
        headerName: "Alert Type",
        width: 200,
      },
      {
        field: "XDR Kill Chain Stage",
        headerName: "XDR Kill Chain Stage",
        width: 220,
      },
      {
        field: "XDR Tactic",
        headerName: "XDR Tactic",
        width: 200,
      },
      {
        field: "XDR Technique",
        headerName: "XDR Technique",
        width: 200,
      },
      {
      	field: "XDR Event Name",
      	headerName: "XDR Event Name",
      	width: 200,
      },
      {
        field: "data_sources_default",
        headerName: "Data Sources",
        // width: 400,
        flex: 1,
        renderCell: (params: GridCellParams) => this.renderTags(params),
      },
    ];

    if (this.props.isAdmin) {
      ProcessesColumns.unshift({
        field: "deleteRows",
        headerName: (
          <Checkbox
            checked={this?.state?.isSelectAll}
            onChange={(evt) => this.handleSelectAllRows(evt)}
          />
        ),
        width: 70,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Checkbox
            checked={params?.row?.isChecked || false} // or true if you want it checked by default
            onChange={(evt) => this.handleDeleteRows(evt, params.id)}
          />
        ),
      });

      ProcessesColumns.push({
        field: "action",
        headerName: "Action",
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: false,
        renderCell: (params) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => this.handleRowEdit(params.row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => this.handleRowDelete(params.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      });
    }

    return ProcessesColumns;
  }

  render() {
    const isLoading = this.state.isLoading;
    const isSourceLoading = this.state.isSourceLoading;
    const detectionsRows = this.state.filteredData;
    // console.log("detectionsRows:", detectionsRows);
    const { pageSize } = this.state;
    const CustomToolbar = () => {
      return (
        <GridToolbarContainer className="d-flex">
          <GridToolbarColumnsButton className="ml-2 custom-grid-toolbar-button" />
          <GridToolbarFilterButton className="custom-grid-toolbar-button " />
          <div className="ml-auto mr-2">
            {this.props.isAdmin && (
              <>
                {!isEmpty(this.state.selectedRowIds) && (
                  <Link
                    className="mr-2 muiLink-button"
                    component="button"
                    onClick={() => this.handleRowDelete()}
                  >
                    <DeleteIcon /> DELETE DETECTION
                  </Link>
                )}
                <Link
                  component="button"
                  className="muiLink-button"
                  onClick={() => this.showAddNewDetection()}
                >
                  <AddIcon /> ADD NEW DETECTION
                </Link>
              </>
            )}
          </div>
        </GridToolbarContainer>
      );
    };

    return (
      <>
        <Container className="full_height">
          {this.isAdmin && (
            <Row>
              <Col xs="6">
                <ActionButton
                  disabled={false}
                  className="float-left action-btn"
                  color="warning"
                  action="Import"
                  version={this.version}
                  label="Import"
                >
                  <img src={importIcon} />
                </ActionButton>
                <ActionButton
                  className="float-left ml-4 action-btn"
                  disabled={false}
                  action="Export"
                  version={this.version}
                  label="Export"
                >
                  <img src={exportIcon} />
                </ActionButton>
              </Col>
              <Col xs="6">
                <ActionButton
                  disabled={false}
                  className="float-right ml-4
                   action-btn"
                  color="primary"
                  action="Create"
                  version={this.version}
                  label="New"
                >
                  <img src={createNewIcon} />
                </ActionButton>
                <ActionButton
                  className="float-right ml-4 action-btn"
                  disabled={false}
                  action="Clone"
                  version={this.version}
                  label="Clone"
                >
                  <img src={cloneIcon} />
                </ActionButton>
                <ActionButton
                  className="float-right ml-4 action-btn"
                  color="danger"
                  disabled={false}
                  action="Delete"
                  version={this.version}
                  label="Delete"
                >
                  <img src={deleteIcon} />
                </ActionButton>
              </Col>
            </Row>
          )}
          {isSourceLoading && this.searchPanel()}
          {!isSourceLoading && <LoaderComponent />}
          {isLoading && <LoaderComponent />}
          {/* {_.isEmpty(resultsData) && (!isLoading && isSourceLoading) ? <NoRecords /> : <DetectionTable isMultiSelect={this.state.isMultiSelect} isSourceLoading={isSourceLoading} isLoading={isLoading} resultsData={resultsData} />} */}
          {!isLoading && (
            <div className="resultTable" style={{ width: "100%" }}>
              <DataGrid
                pageSize="100"
                autoPageSize
                // onPageSizeChange={(newPageSize) =>
                // 	this.setPageSize(newPageSize)
                // }
                // rowsPerPageOptions={[10, 20, 30, 50, 100]}
                // pagination={false}
                autoHeight
                getRowId={(row) => row._id}
                columns={this.getColumns()}
                rows={detectionsRows}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          )}
        </Container>
        <AddNewDataSourceModal
          {...this.state.addNewSourceInfo}
          onCancel={this.resetAddNewSourceInfo}
        />
        {this.state.ToastInfo.isOpen && (
          <ToastComponent {...this.state.ToastInfo} />
        )}
        {this.state.showDeleteDetectionModal && (
          <DeleteDetectionModal
            ids={this.state.selectedRowIds}
            show={this.state.showDeleteDetectionModal}
            onCancel={this.onModalClose}
            version={this.version}
          />
        )}
        {this.state.showEditDetectionModal && (
          <AddEditDetectionModal
            selectedRow={this.state.selectedRow}
            XDR_Kill_Chain_statge_List={this.state.XDR_Kill_Chain_statge_List}
            show={this.state.showEditDetectionModal}
            onCancel={this.onModalClose}
            version={this.version}
          />
        )}
        {this.state.showAddDetectionModal && (
          <AddDetectionModal
            selectedRow={newDetectionPayload}
            XDR_Kill_Chain_statge_List={this.state.XDR_Kill_Chain_statge_List}
            show={this.state.showAddDetectionModal}
            onCancel={this.onModalClose}
            version={this.version}
          />
        )}
      </>
    );
  }
}

export default TableWithFiltersLatest;
