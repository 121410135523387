import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { commonServices } from "../../services";
import CSVPath from "../../../src/detection.csv";
import closeIcon from "../../images/close-icon.png";

const CreateNewModal = ({ show, onCancel, version }) => {
  const [isUploading, setIsUploading] = useState(false);

  const [error, setError] = useState("");

  const fileInputRef = useRef(null);

  const [formData, setFormData] = useState({
    versionName: "detections",
    versionNumber: version,
    selectedFile: "",
  });

  const handleChange = (e) => {
    setError("");
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const closeModal = (action) => {
    setFormData({
      versionName: "detections",
      versionNumber: version,
      selectedFile: "",
    });
    setError("");
    onCancel(action);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsUploading(true);
    commonServices
      .createNewVersion(formData)
      .then((res) => {
        setIsUploading(false);
        closeModal({
          action: "success",
          type: "CreateNew",
          redirectTo: formData.versionNumber,
          message: "File Imported successfully",
        });
      })
      .catch((error) => {
        const errorMsg =
          error.response.data.message || "Failed to create new version.";
        setIsUploading(false);
        console.log("error", error);
        //onCancel({action: 'error', type: 'Import', message: errorMsg})
        setError(errorMsg);
      });
  };

  const handleUpload = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setError("");
    setFormData((prevState) => ({
      ...prevState,
      selectedFile: file,
    }));
  };

  return (
    <Modal isOpen={show} size="xl" centered className="custom-modal">
      <div className="modal-header">
        <h5 className="modal-title">Create New Version</h5>
        {!isUploading && (
          <span className="close-icon" onClick={onCancel}>
            <img src={closeIcon} alt="close icon" />
          </span>
        )}
      </div>
      <ModalBody>
        <div className="modal-body-wrapper">
          <Container className="form-container">
            <Row>
              <Col>
                <Form onSubmit={handleSubmit}>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="versionNumber">
                          Version <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="p-4 custom-border"
                          name="versionNumber"
                          id="versionNumber"
                          value={formData.versionNumber}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="ml-2">
                        <Label for="exampleFile">
                          Upload Excel File{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <input
                          type="file"
                          ref={fileInputRef}
                          accept=".csv, .xlsx"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        <Input
                          type="text"
                          name="selectedFile"
                          id="selectedFile"
                          className="p-4 custom-border"
                          value={formData.selectedFile?.name}
                          onClick={handleUpload}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <FormGroup>
                        <Label style={{ height: "43.5px" }}> </Label>
                        <Button className="btn-lg  ml-5" onClick={handleUpload}>
                          Browse
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                  <span style={{ fontSize: "16px", fontWeight: "600" }}>
                    <a
                      href={CSVPath}
                      download="detection.csv"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Click here
                    </a>{" "}
                    to download excel file template
                  </span>
                  {error && (
                    <Row>
                      <Col md={12}>
                        <div className="text-danger text-center mt-2">
                          {error}
                        </div>
                      </Col>
                    </Row>
                  )}
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </ModalBody>
      <ModalFooter className="modal-custom-footer">
        <Button
          className="btn-custom"
          color="primary"
          disabled={isUploading}
          onClick={onCancel}
        >
          Cancel
        </Button>{" "}
        {!isUploading && (
          <Button
            className="btn-custom"
            disabled={
              !(
                formData.versionName &&
                formData.versionNumber &&
                formData.selectedFile
              )
            }
            color="warning"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
        {isUploading && (
          <Button color="warning" disabled>
            <Spinner size="sm">Loading...</Spinner>
            <span> Uploading</span>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CreateNewModal;
