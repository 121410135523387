import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import jwt from 'jsonwebtoken';
import TableWithFiltersLatest from './components/TableWithFiltersLatest';
import UnAuthorized from './components/UnAuthorized';
import Header from './components/Header';
import Footer from './components/Footer';
import { urlConstants } from './constants';
import Login from './components/Login';
import SignUp from './components/SignUp';
import { commonServices } from './services/index';
import loader from './images/loader.svg';

const App = () => {
  const isLogin = localStorage.getItem('token') ? true : false;
  let [version, setVersion] = useState('Not Identified');

  const [defaultVersion, setDefaultVersion] = useState(null);

  const cookies = new Cookies();
  const query = new URLSearchParams(window.location.search);
  let token = query.get('t');
  let cookieToken = cookies.get("tokenExisted");
  let newCookieToken = cookies.get("authExisted");
  let isCookieTokenVerified = false;

  if (newCookieToken) {
    let decodedToken = jwt.decode(newCookieToken, { complete: true });
    let generatedToken = jwt.sign({ header: decodedToken.header, payload: { exp: decodedToken.payload.exp }, signature: decodedToken.signature }, 'D!P?T3xhd7EwW9Veb*c-mshP_ywuXJG+cwA6mHUX6T!f3H');
    isCookieTokenVerified = jwt.verify(generatedToken, urlConstants.SECRET_KEY);
  } else if (cookieToken) {
    isCookieTokenVerified = true;
  };

  let isTokenVerified = isCookieTokenVerified ? true : false;
  cookies.remove("tokenExisted", { path: '/', expires: '01 Jan 1970 00:00:00 UTC' });
  token = jwt.decode(token, { complete: true });

  if (token && !isCookieTokenVerified) {
    const currentDateTime = new Date();
    const tokenDate = new Date(token.payload.exp * 1000);
    const FIVE_MIN_DELAY = 5 * 60 * 1000;
    let TWELVE_HOURS = new Date().getTime() + 720 * 60 * 1000;
    TWELVE_HOURS = new Date(TWELVE_HOURS);

    if ((currentDateTime - tokenDate) < FIVE_MIN_DELAY) {
      let newtoken = jwt.sign({ header: token.header, payload: { exp: token.payload.exp * 1000 }, signature: token.signature }, 'D!P?T3xhd7EwW9Veb*c-mshP_ywuXJG+cwA6mHUX6T!f3H');
      isTokenVerified = jwt.verify(newtoken, urlConstants.SECRET_KEY);
      if (isTokenVerified) {
        cookies.set('authExisted', newtoken, { path: '/', expires: TWELVE_HOURS });
      }
    }
  }

  const setVersionFrom = function (version) {
    setVersion(version);
  };

  const VersionRoute = ({ children }) => {
    const { version } = useParams();
    setVersion(version);
    return children(version);
  };

  const onDefaultVersionUpdate = (data) => {
    setDefaultVersion(data)
  }

  useEffect(() => {
    commonServices.getDefaultVersion().then((res) => {
      setDefaultVersion(res.data.data[0].version);
      setVersion(version);
    })
  }, []);

  if(!defaultVersion){
    return (
      <div style={{marginTop: '20%', marginLeft: '41%'}}><img src={loader} alt="Loader" /><span>Loading...</span></div>
    )
  }

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/login">
            <Header version={version} isAdmin={false} defaultVersion={defaultVersion} />
            <Login></Login>
          </Route>
          <Route path="/signup">
            <Header version={version} isAdmin={false} defaultVersion={defaultVersion}/>
            <SignUp></SignUp>
          </Route>
          <Route path="/authintication-error/">
            <UnAuthorized />
          </Route>
          <Route path="/v/:version">
            <Header version={version} isAdmin={isLogin} defaultVersion={defaultVersion} onDefaultVersionUpdate={onDefaultVersionUpdate}/>
            <VersionRoute>
              {(version) => <TableWithFiltersLatest version={version} setVersion={setVersionFrom} isAdmin={isLogin}/>}
            </VersionRoute>
          </Route>
          <Route path="/4.3.7"> 
            <Redirect to="/v4.3.7/" />
          </Route>
          <Route path="/v4.3.7">
            <Redirect to="/v/4.3.7" />
          </Route>
          <Route path="/4.3.6"> 
            <Redirect to="/v4.3.5/" />
          </Route>
          <Route path="/v4.3.6/">
            <Redirect to="/v4.3.5" />
          </Route>
          <Route path="/4.3.5"> 
            <Redirect to="/v4.3.5/" />
          </Route>
          <Route path="/v4.3.5/">
            <Redirect to="/v/4.3.5" />
          </Route>
          <Route path="/4.3.4">
            <Redirect to="/v4.3.4/" />
          </Route>
          <Route path="/v4.3.4/">
            <Redirect to="/v/4.3.4" />
          </Route>
          <Route path="/4.2.0">
            <Redirect to="/v4.2.0/" />
          </Route>
          <Route path="/v4.2.0/">
            <Redirect to="/v/4.2.0" />
          </Route>
          <Route path="/authintication-error/">
            <UnAuthorized />
          </Route>
          <Route path="/v3.12.0/">
            <Redirect to="/v/3.12"/>
          </Route>
          <Route path="/">
            <Redirect to={`/v/${defaultVersion}`} />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
