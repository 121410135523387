import axios from 'axios';
import { urlConstants } from '../constants';

const axiosInstance = axios.create({
    baseURL: urlConstants.BASE_URI_DEV
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    config => {
        // Check if there's a token available in your application state or local storage
        const token = localStorage.getItem('token');
        // If a token is available, add it to the Authorization header
        if (token) {
            config.headers['Authorization'] = `${token}`;
        }
        return config;
    },
    error => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    response => {
        // If the response is successful, return it as is
        return response;
    },
    error => {
        // If the error status is 401 or 403, log out the user
        if (error.response.status === 401 || error.response.status === 403) {
            console.log("User logged out due to 401 or 403 error.");
            if (localStorage.getItem('token')) {
                localStorage.setItem('token', '')
                window.location.reload();
            }
        }
        // Forward the error to the calling code
        return Promise.reject(error);
    }
);

function showDetections(seletectedSources, version) {
    return axios.post(urlConstants.BASE_URI_DEV + '/show-detections', { 'seletectedSources': seletectedSources, 'version': version }, { headers: { "Access-Control-Allow-Origin": "*" } });
}

function search(term) {
    return axios.post(urlConstants.BASE_URI_DEV + '/search', { 'term': term }, { headers: { "Access-Control-Allow-Origin": "*" } });
}

function getAllDetections(version) {
    return axios.post(urlConstants.BASE_URI_DEV + '/get-all-detections', { 'version': version }, { headers: { "Access-Control-Allow-Origin": "*" } });
}

function getDataSources() {
    return axios.get(urlConstants.BASE_URI_DEV + '/get-data-sources/', { headers: { "Access-Control-Allow-Origin": "*" } });
}

function getSourceTypes() {
    return axios.get(urlConstants.BASE_URI_DEV + '/get-source-types/', { headers: { "Access-Control-Allow-Origin": "*" } });
}

const Login = (payload) => {
    return axios.post(`${urlConstants.BASE_URI_DEV}/login`, payload, {
        headers: {
            "Access-Control-Allow-Origin": "*"
        }
    });
}

const getAllVersions = () => {
    return axiosInstance.get('/getAllVersions');
}

const exportData = (version) => {
    return axiosInstance.get(`/export/v${version}`, { headers: { "Access-Control-Allow-Origin": "*" }, responseType: 'blob' });
}


const importVersion = (payload) => {
    const { version, formData } = payload;
    return axiosInstance.post(`/upload/v${version}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

}

const createNewVersion = (payload) => {
    const { versionName, versionNumber, selectedFile } = payload;

    const formData = new FormData();
    formData.append('file', selectedFile);

    return axiosInstance.post(`/createNewVersion/${versionName}/${versionNumber}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

const cloneVersion = (payload) => {
    const { versionName, versionNumber, cloneVersion } = payload;

    return axiosInstance.post(`/cloneVersion/${versionName}/${cloneVersion}/${versionNumber}`, {}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

const deleteVersion = (payload) => {
    const { versionNumber } = payload;

    return axiosInstance.delete(`/deleteVersion/${versionNumber}`);
}

const addDataSources = (loginData) => {
    return axiosInstance.post('/add-data-source/', loginData, {
        headers: {
            "Access-Control-Allow-Origin": "*"
        }
    });
}

const deleteDetections = (paload) => {
    const { versionNumber, ids} = paload;
    return axiosInstance.delete(`/deleteDetections/${versionNumber}`, { data: { ids } });
}

const updateDetection = (paload) => {
    const { versionNumber, ...data} = paload;
    return axiosInstance.put(`/updateDetection/${versionNumber}`, data);
}

const getDefaultVersion = () => {
    return axiosInstance.get(`/updateDefaultVersion`);
}

const updateDefaultVersion = (paload) => {
    return axiosInstance.put(`/updateDefaultVersion`, paload)
}

export const commonServices = {
    showDetections,
    search,
    getAllDetections,
    getDataSources,
    getSourceTypes,
    Login,
    getAllVersions,
    importVersion,
    exportData,
    addDataSources,
    createNewVersion,
    cloneVersion,
    deleteVersion,
    deleteDetections,
    updateDetection,
    getDefaultVersion,
    updateDefaultVersion
};