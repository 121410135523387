import React, { useState, useRef } from "react";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { commonServices } from "../../services";
import CSVPath from "../../../src/detection.csv";
import closeIcon from "../../images/close-icon.png";
import uploadImg from "../../images/upload-icon.png";

const ImportModal = ({ show, headerTitle, onCancel, version }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [error, setError] = useState("");

  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    if (selectedFile) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);

      commonServices
        .importVersion({ formData, version })
        .then((response) => {
          console.log("File uploaded successfully:", response.data);
          setIsUploading(false);
          onCancel({
            action: "success",
            type: "Import",
            message: "File Imported successfully",
          });
        })
        .catch((error) => {
          setIsUploading(false);
          const errorMsg =
            error.response.data.message || "Failed to import version.";
          setError(errorMsg);
          //onCancel({ action: 'error', type: 'Import', message: errorMsg });
          console.error(errorMsg);
        });
    }
  };

  return (
    <Modal isOpen={show} size="xl" centered className="custom-modal">
      <div className="modal-header">
        <h5 className="modal-title">{headerTitle}</h5>
        {!isUploading && (
          <span
            className="close-icon"
            onClick={() => onCancel({ action: "cancel" })}
          >
            <img src={closeIcon} alt="close icon" />
          </span>
        )}
      </div>
      <ModalBody onDragOver={handleDragOver} onDrop={handleDrop}>
        <div className="modal-body-wrapper">
          <div className="modal-content">
            <div className="upload-icon">
              <img src={uploadImg} alt="upload icon" />
            </div>
            <div className="label-text">Drag and Drop files here</div>
            <div className="label-subtext">Files Supported: .CSV, XLSX</div>
            <input
              type="file"
              accept=".csv, .xlsx"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <div className="button-box">
              <Button
                color="primary"
                onClick={() => fileInputRef.current.click()}
              >
                Choose File
              </Button>
            </div>
            <div className="button-box-subtext">
              Maximum size: 300MB<span>*</span>
            </div>
            <div className="file-counter">
              {selectedFile
                ? `1 file selected: ${selectedFile.name}`
                : "0 files selected"}
            </div>
            <div className="text-center">
              <a href={CSVPath} download="detection.csv">
                Download Templete CSV
              </a>
            </div>
          </div>
          {error && <div className="text-danger text-center mt-2">{error}</div>}
        </div>
      </ModalBody>
      <ModalFooter className="modal-custom-footer">
        <Button
          className="btn-custom"
          color="primary"
          disabled={isUploading}
          onClick={() => onCancel({ action: "cancel" })}
        >
          Cancel
        </Button>{" "}
        {!isUploading && (
          <Button
            className="btn-custom"
            color="warning"
            onClick={handleSubmit}
            disabled={!selectedFile}
          >
            Submit
          </Button>
        )}
        {isUploading && (
          <Button color="warning" disabled>
            <Spinner size="sm">Loading...</Spinner>
            <span> Uploading</span>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ImportModal;
