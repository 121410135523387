import React from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ToastComponent = ({ message, type, isOpen }) => {
    return (
        <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 9999, animation: `${isOpen ? 'slideInRight' : 'slideOutRight'} 0.5s ease forwards` }} className={`bg-${type}`}>
            <Toast isOpen={isOpen} style={{ width: 300, animation: `${isOpen ? 'slideInRight' : 'slideOutRight'} 0.5s ease forwards` }}>
                <ToastHeader>
                    {type === 'info' ? 'Success' : 'Error' }
                </ToastHeader>
                <ToastBody>
                    {message}
                </ToastBody>
            </Toast>
        </div>
    );
};

export default ToastComponent;
