import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { isEmpty } from "lodash";
import { DataGrid } from "@material-ui/data-grid";
import { commonServices } from "../../services/";
import closeIcon from "../../images/close-icon.png";

const AddNewDataSourceModal = ({
  show,
  dataSource,
  onCancel,
  existingDataSources,
}) => {
  console.log("existingDataSources", existingDataSources);

  const [formData, setFormData] = useState({
    name: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Clear error and remove red border when user starts typing
    setError("");
  };

  const closeModal = (action) => {
    setFormData({ name: "" });
    setError("");
    onCancel(action);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name } = formData;
    // Validate input
    if (!name) {
      setError("Name is required.");
      return;
    }

    const payload = {
      name: name,
      _source_type_id: dataSource,
      _source_type: dataSource,
    };

    commonServices
      .addDataSources(payload)
      .then((res) => {
        //console.log(res);
        closeModal("success");
      })
      .catch((error) => {
        console.log("error", error);
        setError(error.response.data.message || "Failed to add data source.");
      });
  };

  const ProcessesColumns = [
    {
      field: "label",
      headerName: `Existing ${dataSource}`,
      headerClassName: "custom-header",
      flex: 1,
    },
    {
      field: "id",
      headerName: "ID",
      headerClassName: "custom-header",
      flex: 1,
      valueGetter: (params) => params.row._id, // Display the _id value
    },
  ];

  return (
    <Modal isOpen={show} size="xl" centered className="custom-modal">
      <div className="modal-header">
        <h5 className="modal-title">Add New {dataSource}</h5>
        <span className="close-icon" onClick={() => closeModal("cancel")}>
          <img src={closeIcon} alt="close icon" />
        </span>
      </div>
      <ModalBody>
        <div className="modal-body-wrapper">
          <Container className="form-container">
            <Row>
              <Col>
                <Form onSubmit={handleSubmit}>
                  <Row form className="align-items-center w-100">
                    <Col md={9}>
                      <FormGroup>
                        <Label for="name">
                          {dataSource} Name{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          value={formData.name}
                          onChange={handleChange}
                          className={`${
                            error ? "is-invalid" : ""
                          } p-4 custom-border`}
                        />
                        {/* Display error message */}
                        {error && (
                          <div className="invalid-feedback">{error}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={3} className="text-md-right pt-3">
                      <Button
                        className="btn btn-lg btn-custom btn-primary  text-nowrap"
                        color="primary"
                        style={{ minWidth: "auto" }}
                        disabled={!formData.name || error}
                        onClick={handleSubmit}
                      >
                        Add new {dataSource}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {!isEmpty(existingDataSources) && (
                  <DataGrid
                    autoPageSize
                    autoHeight
                    pageSize={5}
                    getRowId={(row) => row._id}
                    columns={ProcessesColumns}
                    rows={existingDataSources}
                    headerClassName="custom-header"
                    rowHeight={40} // Use the custom row height
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </ModalBody>
      <ModalFooter className="modal-custom-footer">
        <Button
          className="btn-custom"
          color="primary"
          onClick={() => closeModal("cancel")}
        >
          Done
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default AddNewDataSourceModal;
