import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { Container, Col, Row, Button } from "reactstrap";
import { find } from "lodash";
import OpenXDRLoopImage from "../images/open-xdr-graphic-illustration.svg";
import { commonServices } from "../services/index";
import Logo from "../images/stellar-cyber-logo-2023.svg";
import ToastComponent from "./ToastComponent";
import { TOAST_TIMEOUT } from "../constants/";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const ToastDefaultData = {
  message: "",
  type: "",
  isOpen: false,
};

const Header = ({
  _version,
  isAdmin,
  defaultVersion,
  onDefaultVersionUpdate,
}) => {
  const history = useHistory();

  const { version } = useParams();

  const [versions, setVersions] = useState([]);
  const [selectedVesrion, setSelectedVesrion] = useState({
    label: "",
    value: "",
  });
  const [versionNumber, setVersionNumber] = useState("");
  const [ToastInfo, setToastInfo] = useState(ToastDefaultData);

  const onVersionChange = (item) => {
    setSelectedVesrion(item);
    history.push(`/v/${item.value}`);
  };

  const logout = () => {
    localStorage.setItem("token", "");
    window.location.reload();
  };

  const updateDefaultVersion = () => {
    commonServices.updateDefaultVersion({ version: version }).then((res) => {
      onDefaultVersionUpdate(version);
      setToastInfo({
        message: "Updated Default version successfully !!",
        type: "info",
        isOpen: true,
      });
      setTimeout(() => {
        setToastInfo(ToastDefaultData);
      }, TOAST_TIMEOUT);
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      commonServices.getAllVersions().then((res) => {
        const detectionsVersions = res?.data?.detections;
        if (detectionsVersions) {
          const arrayOfObjects = detectionsVersions.map((item) => {
            const value = item.replace("v", "");
            return { label: item, value: value };
          });
          setVersions(arrayOfObjects);
        }
      });
    }
  }, []);

  useEffect(() => {
    const item = find(versions, { value: version });
    setVersionNumber(version);
    if (item) {
      setSelectedVesrion(item);
    }
  }, [version, versions]);

  return (
    <div className="header">
      <div className="topbar">
        <Container>
          <img src={Logo} alt="Stellar Cyber" className="company_logo" />
          {isAdmin && (
            <Button style={{ float: "right" }} onClick={logout}>
              <ExitToAppIcon className="mr-2" />
              Logout
            </Button>
          )}
        </Container>
      </div>
      <div className="header_background">
        <Container>
          <h1>Detections & Response</h1>
          {isAdmin ? (
            <Row style={{ height: "70px" }}>
              <Col xs="1" style={{ textAlign: "left" }}>
                <label>Select Version</label>
              </Col>
              <Col xs="3" key={"index"} style={{ color: "black" }}>
                <Select
                  options={versions}
                  className="basic-multi-select"
                  onChange={onVersionChange}
                  value={selectedVesrion}
                />
              </Col>
              <Col xs="3" key={"index"} style={{ color: "black" }}>
                <Button
                  className="btn-custom"
                  color="primary"
                  onClick={() => updateDefaultVersion()}
                  disabled={defaultVersion == version}
                >
                  Set Default Version
                </Button>
              </Col>
            </Row>
          ) : (
            <Row style={{ height: "50px" }}>
              {versionNumber !== "Not Identified" && (
                <h4>{`VERSION ${versionNumber}`}</h4>
              )}
            </Row>
          )}
          <img
            src={OpenXDRLoopImage}
            alt="Stellar Cyber's Open XDR"
            className="open-xdr-loop-img"
          />
        </Container>
      </div>
      {ToastInfo.isOpen && <ToastComponent {...ToastInfo} />}
    </div>
  );
};

export default Header;
