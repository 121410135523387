import React from 'react';
import ImportModal from './ImportModal';
import CreateNewModal from './CreateNewModal';
import CloneModal from './CloneModal';
import DeleteModal from './DeleteModal';

const ActionModal = ({ action, ...props }) => {

    return (
        <>
            {action === 'Import' && <ImportModal {...props} />}
            {action === 'Create' && <CreateNewModal {...props} />}
            {action === 'Clone' && <CloneModal {...props} />}
            {action === 'Delete' && <DeleteModal {...props} />}
        </>
    )
}

export default ActionModal