const urlConstants = {
    BASE_URI_DEV: 'https://detections-api.herokuapp.com',
    //BASE_URI_DEV: 'http://localhost:5000',
    //BASE_URI_DEV: 'https://detections-api-dev.herokuapp.com',
    SECRET_KEY: 'D!P?T3xhd7EwW9Veb*c-mshP_ywuXJG+cwA6mHUX6T!f3H'
};

const TOAST_TIMEOUT = 1500;

const newDetectionPayload = {
    "XDR Kill Chain Stage": "",
    "XDR Tactic": "",
    "XDR Display Name": "",
    "XDR Tags": "",
    "XDR Technique": "",
    "XDR Event Name": "",
    "Categories": "",
    "Detection Model": "",
    "Internal Notes": "",
    "Index": "",
    "Relevant Minimum Data Fields for code and display": "",
    "Required Data Fields for Enrichment": "",
    "Data Class": "dpi_traffic",
    "data_sources_default": "",
    "data_sources_dependency": "",
    "data_sources_optional": "",
    "data_sources_recommended": "",
    "data_sources_required": ""
}

export {urlConstants, TOAST_TIMEOUT, newDetectionPayload}

