import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { commonServices } from "../../services";
import closeIcon from "../../images/close-icon.png";

const CloneModal = ({ show, onCancel, version }) => {
  const [isUploading, setIsUploading] = useState(false);

  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    versionName: "detections",
    versionNumber: version,
    cloneVersion: version,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const closeModal = (action) => {
    setFormData({
      versionName: "detections",
      versionNumber: version,
      cloneVersion: version,
    });
    setError("");
    onCancel(action);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Do something with form data
    console.log(formData);

    setIsUploading(true);
    commonServices
      .cloneVersion(formData)
      .then((res) => {
        setIsUploading(false);
        closeModal({
          action: "success",
          type: "clone",
          redirectTo: formData.versionNumber,
          message: "Version cloned successfully",
        });
      })
      .catch((error) => {
        const errorMsg =
          error.response.data.message || "Failed to clone new version.";
        setIsUploading(false);
        console.log("error", error);
        //onCancel({action: 'error', type: 'Import', message: errorMsg})
        setError(errorMsg);
      });
  };

  return (
    <Modal isOpen={show} size="xl" centered className="custom-modal">
      <div className="modal-header">
        <h5 className="modal-title">Clone Version</h5>
        {!isUploading && (
          <span className="close-icon" onClick={onCancel}>
            <img src={closeIcon} alt="close icon" />
          </span>
        )}
      </div>
      <ModalBody>
        <div className="modal-body-wrapper">
          <Container className="form-container">
            <Row>
              <Col>
                <Form onSubmit={handleSubmit}>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="versionNumber">
                          Version <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="versionNumber"
                          id="versionNumber"
                          className="p-4 custom-border"
                          value={formData.versionNumber}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {error && (
                    <Row>
                      <Col md={12}>
                        <div className="text-danger text-center mt-2">
                          {error}
                        </div>
                      </Col>
                    </Row>
                  )}
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </ModalBody>
      <ModalFooter className="modal-custom-footer">
        <Button
          className="btn-custom"
          color="primary"
          disabled={isUploading}
          onClick={onCancel}
        >
          Cancel
        </Button>{" "}
        {!isUploading && (
          <Button
            className="btn-custom"
            disabled={!(formData.versionName && formData.versionNumber)}
            color="warning"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
        {isUploading && (
          <Button color="warning" disabled>
            <Spinner size="sm">Loading...</Spinner>
            <span> Uploading</span>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CloneModal;
