import React, { useState } from "react";
import { isEmpty } from "lodash";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { commonServices } from "../../services";
import closeIcon from "../../images/close-icon.png";
import errorIcon from "../../images/Error.png";

const DeleteModal = ({ show, onCancel, version }) => {
  const [isUploading, setIsUploading] = useState(false);

  const handleSubmit = () => {
    setIsUploading(true);
    commonServices
      .deleteVersion({ versionNumber: version })
      .then((res) => {
        commonServices.getAllVersions().then((response) => {
          console.log("response", response);

          const detectionsVersions = response?.data?.detections;
          if (detectionsVersions) {
            const versionsList = detectionsVersions.map((item) => {
              const value = item.replace("v", "");
              return { label: item, value: value };
            });

            const redirectTo = isEmpty(versionsList)
              ? "4.3.7"
              : versionsList[0].value;
            onCancel({
              action: "success",
              type: "delete",
              redirectTo: redirectTo,
              message: res?.data?.message,
            });
          }
          setIsUploading(false);
        });
      })
      .catch((error) => {
        setIsUploading(false);
      });
  };

  return (
    <Modal isOpen={show} size="xl" centered className="custom-modal">
      <div className="modal-header">
        <h5 className="modal-title text-danger">Delete Confirmation !</h5>
        {!isUploading && (
          <span className="close-icon" onClick={onCancel}>
            <img src={closeIcon} alt="close icon" />
          </span>
        )}
      </div>
      <ModalBody>
        <div className="modal-body-wrapper">
          <div className="modal-content">
            <div className="upload-icon">
              <img src={errorIcon} alt="upload icon" />
            </div>
            <div className="label-text">
              Are you sure to delete the selected version of Detections?
            </div>
            <div className="label-text">
              This action will permanently remove the data associated with the
              selected version.
            </div>
            <div className="label-text">Kindly confirm!</div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="modal-custom-footer">
        <Button
          className="btn-custom"
          color="primary"
          disabled={isUploading}
          onClick={onCancel}
        >
          Cancel
        </Button>{" "}
        {!isUploading && (
          <Button className="btn-custom" color="warning" onClick={handleSubmit}>
            Ok
          </Button>
        )}
        {isUploading && (
          <Button color="warning" disabled>
            <Spinner size="sm">Loading...</Spinner>
            <span> Uploading</span>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
