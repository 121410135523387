import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { commonServices } from '../services';

const Login = () => {
  const history = useHistory();
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({ email: '', password: '', auth: '' });

  const validateForm = () => {
    let valid = true;
    const newErrors = { email: '', password: '' };

    if (!loginData.email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(loginData.email)) {
      newErrors.email = 'Email is invalid';
      valid = false;
    }

    if (!loginData.password) {
      newErrors.password = 'Password is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const onSubmit = async () => {
    if (validateForm()) {
      commonServices.Login(loginData).then((response) => {
        if (response.status === 200) {
          localStorage.setItem('token', response.data.token);
          setTimeout(() => {
            history.push(`/`);
          });
        }
      }).catch((error) => {
        setErrors({ ...errors, auth: error?.response?.data?.message || 'Authentication failed' });
      });
    }
  };

  const onChange = (evt) => {
    const { name, value } = evt.target;
    setLoginData({ ...loginData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <div className='login-screen'>
      <div className='login-container'>
        <div className='header'>Login</div>
        <div className='body'>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">
                Email <span className='text-danger'>*</span>
              </Label>
              <Input
                id="exampleEmail"
                name="email"
                placeholder="Enter email address"
                type="email"
                onChange={onChange}
                value={loginData.email}
                onKeyPress={handleKeyPress}
                style={{ borderColor: errors.email ? 'red' : '' }}
              />
              {errors.email && <span className="text-danger">{errors.email}</span>}
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">
                Password <span className='text-danger'>*</span>
              </Label>
              <Input
                id="examplePassword"
                name="password"
                placeholder="*****"
                type="password"
                onChange={onChange}
                value={loginData.password}
                onKeyPress={handleKeyPress}
                style={{ borderColor: errors.password ? 'red' : '' }}
              />
              {errors.password && <span className="text-danger">{errors.password}</span>}
            </FormGroup>
            {errors.auth && <div className="text-danger">{errors.auth}</div>}
          </Form>

          <div style={{ textAlign: 'center' }}>
            <Button color="warning" onClick={onSubmit}>
              Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
