import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select, { createFilter } from "react-select";
import { commonServices } from "../../services";
import closeIcon from "../../images/close-icon.png";
import { isEmpty, map } from "lodash";
import AddIcon from "@material-ui/icons/Add";

const AddDetectionModal = ({
  show,
  XDR_Kill_Chain_statge_List,
  selectedRow,
  onCancel,
  version,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    Detection_Name: selectedRow["XDR Display Name"],
    versionNumber: version,
    XDR_Kill_Chain_stage: selectedRow["XDR Kill Chain Stage"],
    XDR_Tactic: selectedRow["XDR Tactic"],
    XDR_Technique: selectedRow["XDR Technique"],
    data_sources_default: selectedRow.data_sources_default,
    data_sources_dependency: selectedRow.data_sources_dependency,
    data_sources_optional: selectedRow.data_sources_optional,
    data_sources_recommended: selectedRow.data_sources_recommended,
    data_sources_required: selectedRow.data_sources_required,
  });

  const [severityList] = useState([
    { label: "Optional", value: "data_sources_optional" },
    { label: "Default", value: "data_sources_default" },
    { label: "Dependency", value: "data_sources_dependency" },
    { label: "Recommended", value: "data_sources_recommended" },
    { label: "Required", value: "data_sources_required" },
  ]);

  const [dataSources, setDataSources] = useState([]);

  const [dataSourceName, setDataSourceName] = useState({
    label: "",
    value: "",
  });
  const [dataSourceSeverity, setDataSourceSeverity] = useState({
    label: "Default",
    value: "data_sources_default",
  });

  useEffect(() => {
    commonServices
      .getDataSources()
      .then((res) => {
        console.log("res", res.data.data_sources);
        let newDataSource = [];
        if (!isEmpty(res.data.data_sources)) {
          map(res.data.data_sources, (item) => {
            newDataSource.push({ label: item.name, value: item._id });
          });
          setDataSources(newDataSource);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSeverityChange = (item) => {
    setDataSourceSeverity(item);
  };

  const handleDataSourceNameChange = (item) => {
    setDataSourceName(item);
  };

  const removeDataSource = (data) => {
    const formDataCopy = { ...formData };
    const dataSourceKey = `data_sources_${data.class}`;
    const temp = formDataCopy[dataSourceKey]
      .split(",")
      .filter((item) => item !== data.name)
      .join(",");
    formDataCopy[dataSourceKey] = temp;
    setFormData(formDataCopy);
  };

  const addDataSource = () => {
    let newFormData = { ...formData };
    newFormData[dataSourceSeverity.value] =
      newFormData[dataSourceSeverity.value] + "," + dataSourceName.value;
    setFormData(newFormData);
    setDataSourceName({ label: "", value: "" });
    setDataSourceSeverity({ label: "Default", value: "data_sources_default" });
  };

  const handleChange = (evt, type) => {
    console.log(evt, type);
    setError("");
    if (type) {
      setFormData((prevState) => ({
        ...prevState,
        [type]: evt.value,
      }));
    } else {
      const { name, value } = evt.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const closeModal = (action) => {
    setFormData({
      versionName: "detections",
      versionNumber: version,
      selectedFile: "",
    });
    setError("");
    onCancel(action);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsUploading(true);
    const payload = {
      ...selectedRow,
      versionNumber: formData.versionNumber,
      "XDR Kill Chain Stage": formData.XDR_Kill_Chain_stage,
      "XDR Display Name": formData.Detection_Name,
      "XDR Tactic": formData.XDR_Tactic,
      "XDR Technique": formData.XDR_Technique,
      data_sources_default: formData.data_sources_default,
      data_sources_dependency: formData.data_sources_dependency,
      data_sources_optional: formData.data_sources_optional,
      data_sources_recommended: formData.data_sources_recommended,
      data_sources_required: formData.data_sources_required,
    };

    console.log("payload", payload);

    commonServices
      .updateDetection(payload)
      .then((res) => {
        setIsUploading(false);
        closeModal({
          action: "success",
          type: "CreateNew",
          redirectTo: formData.versionNumber,
          message: res?.data?.message || "Detection updated successfully",
        });
      })
      .catch((error) => {
        const errorMsg =
          error.response.data.message || "Failed to create new version.";
        setIsUploading(false);
        console.log("error", error);
        //onCancel({action: 'error', type: 'Import', message: errorMsg})
        setError(errorMsg);
      });
  };

  const renderTags = (row) => {
    let tagData = [];

    const processArray = (dataArray, className) => {
      if (dataArray instanceof Array) {
        const filteredArray = dataArray.filter((item) => item.trim() !== "");
        tagData.push(
          ...filteredArray.map((d) => ({ name: d, class: className }))
        );
      } else if (typeof dataArray === "string") {
        const splitArray = dataArray
          .split(",")
          .map((s) => s.trim())
          .filter((s) => s !== "");
        tagData.push(...splitArray.map((s) => ({ name: s, class: className })));
      }
    };

    processArray(row["data_sources_default"], "default");
    processArray(row["data_sources_optional"], "optional");
    processArray(row["data_sources_recommended"], "recommended");
    processArray(row["data_sources_required"], "required");
    processArray(row["data_sources_dependency"], "dependency");

    return (
      tagData.length > 0 &&
      tagData.map((d, index) => {
        const cellValue = d.name.replace(/_/g, " ");
        return (
          <span className={"significance " + d.class} key={index}>
            {cellValue} <span onClick={() => removeDataSource(d)}>X</span>
          </span>
        );
      })
    );
  };

  return (
    <Modal isOpen={show} size="xl" centered className="custom-modal">
      <div className="modal-header">
        <h5 className="modal-title">Add New Detection</h5>
        {!isUploading && (
          <span
            className="close-icon"
            onClick={() => onCancel({ action: "cancel" })}
          >
            <img src={closeIcon} alt="close icon" />
          </span>
        )}
      </div>
      <ModalBody>
        <div className="modal-body-wrapper">
          <Container className="form-container">
            <Row>
              <Col>
                <Form onSubmit={handleSubmit}>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="Detection_Name">
                          Detection Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="Detection_Name"
                          id="Detection_Name"
                          value={formData.Detection_Name}
                          onChange={handleChange}
                          className="p-4  custom-border"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="ml-2">
                        <Label for="XDR_Kill_Chain_stage">
                          XDR Kill Chain stage{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Select
                          name="XDR_Kill_Chain_stage"
                          options={XDR_Kill_Chain_statge_List}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          closeMenuOnSelect={true}
                          placeholder="XDR Kill Chain Stage"
                          filterOption={createFilter({
                            ignoreCase: true,
                            ignoreAccents: true,
                            trim: true,
                            matchFromStart: false,
                          })}
                          value={{
                            label: formData.XDR_Kill_Chain_stage,
                            value: formData.XDR_Kill_Chain_stage,
                          }}
                          onChange={(evt) =>
                            handleChange(evt, "XDR_Kill_Chain_stage")
                          }
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              padding: "5px", // Increase padding
                              borderRadius: "10px",
                              borderWidth: "2px", // Set border width
                              borderStyle: "solid", // Make it more rounded
                            }),
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="XDR_Tactic">
                          XDR Tactic<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="XDR_Tactic"
                          id="XDR_Tactic"
                          value={formData.XDR_Tactic}
                          onChange={handleChange}
                          className="p-4 custom-border"
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col md={6}>
                                            <FormGroup>
                                                <Label for="XDR_Technique">XDR Technique <span className='text-danger'>*</span></Label>
                                                <Select
                                                    name="XDR_Technique"
                                                    options={XDR_Techniques}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    closeMenuOnSelect={true}
                                                    placeholder="XDR Technique"
                                                    filterOption={createFilter({
                                                        ignoreCase: true,
                                                        ignoreAccents: true,
                                                        trim: true,
                                                        matchFromStart: false,
                                                    })}
                                                    value={{label: formData.XDR_Technique, value: formData.XDR_Technique}}
                                                    onChange={(evt) => handleChange(evt, 'XDR_Technique')}
                                                />
                                            </FormGroup>
                                        </Col> */}
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="data_sources_default">
                          Data Sources<span className="text-danger">*</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="XDR_Tactic">Name</Label>
                        <Select
                          name="Name"
                          options={dataSources}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          closeMenuOnSelect={true}
                          placeholder="Name"
                          filterOption={createFilter({
                            ignoreCase: true,
                            ignoreAccents: true,
                            trim: true,
                            matchFromStart: false,
                          })}
                          value={dataSourceName}
                          onChange={handleDataSourceNameChange}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              padding: "5px", // Increase padding
                              borderRadius: "10px",
                              borderWidth: "2px", // Set border width
                              borderStyle: "solid", // Make it more rounded
                            }),
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="ml-2">
                        <Label for="Severity">Severity</Label>
                        <Select
                          name="Severity"
                          options={severityList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          closeMenuOnSelect={true}
                          placeholder="Severity"
                          filterOption={createFilter({
                            ignoreCase: true,
                            ignoreAccents: true,
                            trim: true,
                            matchFromStart: false,
                          })}
                          value={dataSourceSeverity}
                          onChange={handleSeverityChange}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              padding: "5px", // Increase padding
                              borderRadius: "10px",
                              borderWidth: "2px", // Set border width
                              borderStyle: "solid", // Make it more rounded
                            }),
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <FormGroup>
                        <Button
                          style={{ verticalAlign: "-40px" }}
                          disabled={!dataSourceName?.value}
                          className="btn-lg  ml-5"
                          color="primary"
                          onClick={addDataSource}
                        >
                          <AddIcon className="mr-1" />
                          Add Data Source
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col sm="12">
                      <FormGroup>
                        {/* <Label for="data_sources_default">Select Data Sources<span className='text-danger'>*</span></Label> */}
                        {/* <Input type="text" name="data_sources_default" id="data_sources_default" value={formData.data_sources_default} onChange={handleChange} /> */}
                        <div className="dataSourceTags custom-border">
                          {renderTags(formData)}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {error && (
                    <Row>
                      <Col md={12}>
                        <div className="text-danger text-center mt-2">
                          {error}
                        </div>
                      </Col>
                    </Row>
                  )}
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </ModalBody>
      <ModalFooter className="modal-custom-footer">
        <Button
          className="btn-custom"
          color="primary"
          disabled={isUploading}
          onClick={() => onCancel({ action: "cancel" })}
        >
          Cancel
        </Button>{" "}
        {!isUploading && (
          <Button className="btn-custom" color="warning" onClick={handleSubmit}>
            Submit
          </Button>
        )}
        {isUploading && (
          <Button color="warning" disabled>
            <Spinner size="sm">Loading...</Spinner>
            <span> Updating</span>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default AddDetectionModal;
