import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap';
import axios from 'axios';
import { urlConstants } from '../constants/index';

const SignUp = () => {
 

  const initialSignupData = { name: '', email: '', password: '', confirmPassword: '' };
  const [signupData, setSignupData] = useState(initialSignupData);
  const [showConfirmPwdError, setShowConfirmPwdError] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [invalidEmail, setInvalidEmail] = useState(false);

  useEffect(() => {
    isSignUpEnabled();
  }, [signupData]);

  const isInValidInputs = () => {
    let isError = false;
    let confirmPassError = false;

    if (signupData.password !== signupData.confirmPassword) {
      isError = true;
      confirmPassError = true;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(signupData.email)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
      isError = true;
    }

    setShowConfirmPwdError(confirmPassError);

    return isError;
  };

  const onSubmit = async () => {
    // Exclude confirmPassword from the payload sent to the API
    const { confirmPassword, ...dataToSend } = signupData;

    if (isInValidInputs()) {
      return;
    }

    try {
      const response = await axios.post(`${urlConstants.BASE_URI_DEV}/signup`, dataToSend, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });

      console.log('response', response);

      // Handle successful signup response
      // if (response.status === 201) {
      //   localStorage.setItem('token', response.data.token);
      //   dispatch({ type: 'UPDATE_LOGIN_STATUS', value: true });
      //   dispatch({ type: 'UPDATE_USER_INFO', value: response.data });
      //   history.push('/');
      // }
    } catch (error) {
      // Handle signup failure, show an error message, etc.
      console.error('Signup failed:', error);
    }
  };

  const onChange = (evt) => {
    const { name, value } = evt.target;
    setSignupData({ ...signupData, [name]: value });
  };

  const isSignUpEnabled = () => {
    const { password, confirmPassword, email, name } = signupData;
    setDisabledSubmit(!(password && confirmPassword && email && name));
  };

  return (
    <div className="login-screen">
      <div className="login-container signup-container">
        <div className="header">Sign Up</div>
        <div className="body">
          <Form>
            <FormGroup>
              <Label for="exampleName">Name</Label>
              <Input
                id="exampleName"
                name="name"
                placeholder="Enter your name"
                type="text"
                onChange={onChange}
                value={signupData.name}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Email</Label>
              <Input
                id="exampleEmail"
                name="email"
                placeholder="Enter address"
                type="email"
                onChange={onChange}
                value={signupData.email}
                invalid={invalidEmail}
              />
              <FormFeedback>Invalid email address</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Password</Label>
              <Input
                id="examplePassword"
                name="password"
                placeholder="*****"
                type="password"
                onChange={onChange}
                value={signupData.password}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleConfirmPassword">Confirm Password</Label>
              <Input
                id="exampleConfirmPassword"
                name="confirmPassword"
                placeholder="*****"
                type="password"
                onChange={onChange}
                value={signupData.confirmPassword}
                invalid={showConfirmPwdError}
              />
              <FormFeedback>Passwords do not match</FormFeedback>
            </FormGroup>
          </Form>
          <div style={{ textAlign: 'center' }}>
            <Button color="warning" onClick={onSubmit} disabled={disabledSubmit}>
              Sign Up
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
